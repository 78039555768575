let eventGuid = 0

export const INITIAL_EVENTS = [
  // {
  //   id: createEventId(),
  //   title: 'Eventos Testes',
  //   start: new Date().setDate(new Date().getDate() + 2)
  // },
  // {
  //   id: createEventId(),
  //   title: 'Segundo Evento teste',
  //   start: new Date().setDate(new Date().getDate() + 3)
  // }
]

export function createEventId() {
  return String(eventGuid++)
}